@bootstrapFolder : '../../../../node_modules/bootstrap-less/bootstrap/';

// Core variables and mixins



@import "variables.less";
@import "@{bootstrapFolder}mixins.less";

// Reset and dependencies
@import "@{bootstrapFolder}normalize.less";
//@import "@{bootstrapFolder}print.less";
//@import "@{bootstrapFolder}glyphicons.less";

// Core CSS
@import "@{bootstrapFolder}scaffolding.less";
@import "@{bootstrapFolder}type.less";
//@import "@{bootstrapFolder}code.less";
@import "@{bootstrapFolder}grid.less";
//@import "@{bootstrapFolder}tables.less";
@import "@{bootstrapFolder}forms.less";
@import "@{bootstrapFolder}buttons.less";

// Components
//@import "@{bootstrapFolder}component-animations.less";
//@import "@{bootstrapFolder}dropdowns.less";
//@import "@{bootstrapFolder}button-groups.less";
//@import "@{bootstrapFolder}input-groups.less";
@import "@{bootstrapFolder}navs.less";
@import "@{bootstrapFolder}navbar.less";
//@import "@{bootstrapFolder}breadcrumbs.less";
//@import "@{bootstrapFolder}pagination.less";
//@import "@{bootstrapFolder}pager.less";
//@import "@{bootstrapFolder}labels.less";
//@import "@{bootstrapFolder}badges.less";
//@import "@{bootstrapFolder}jumbotron.less";
//@import "@{bootstrapFolder}thumbnails.less";
//@import "@{bootstrapFolder}alerts.less";
//@import "@{bootstrapFolder}progress-bars.less";
//@import "@{bootstrapFolder}media.less";
//@import "@{bootstrapFolder}list-group.less";
//@import "@{bootstrapFolder}panels.less";
//@import "@{bootstrapFolder}responsive-embed.less";
@import "@{bootstrapFolder}wells.less";
@import "@{bootstrapFolder}close.less";

// Components w/ JavaScript
@import "@{bootstrapFolder}modals.less";
//@import "@{bootstrapFolder}tooltip.less";
//@import "@{bootstrapFolder}popovers.less";
//@import "@{bootstrapFolder}carousel.less";

// Utility classes
@import "@{bootstrapFolder}utilities.less";
@import "@{bootstrapFolder}responsive-utilities.less";

@import "../../../../node_modules/font-awesome/less/font-awesome.less";

@fa-font-path: "../../fonts";

@fontMd : 14px;

body.home {
  background: #000 url('/assets/front/img/bghome.jpg') no-repeat center -150px;
  background-size: 1280px;
}
body{
  padding-top: 70px;
}
.navbar{
  margin-bottom: 0;
}
.navbar-brand{
  text-decoration: none;
  font-family: 'Arvo', serif;
  font-weight: 700;
  font-size: 24px
}
.navbar-inverse .navbar-brand {
  color: #D9D4BF;
}

.navbar{
  max-width: @container-lg;
  margin:auto;
  background-color: black;
}

#menu-general li{
  float:none;
  display: inline-block;
}

#menu{
  float: left;
}


.beige{
  color:#D9D4BF;
}
.white{
  color:white;
}

div#coming
{
  height: 730px;
}

.nav a{
  text-decoration: none;
}

body{
  font-family: 'Arvo', serif;
  background-color: white;
}
a{
  color:#292626;
  text-decoration: underline;
}

a.btn{
  text-decoration: none;
}



a:hover{
  color: #292626
}
h1{
  font-weight: 700;
}
#press{
  color: #4d4d4d;
}

#top-bar{
  background-color:black
}

#top-bar #menu{
  list-style: none;
  margin:auto 0;
  padding:0;
  color:white;
  text-align: center;
}
#top-bar #menu li{
  display: inline-block;
  padding: 1em;
  font-size: @fontMd;
  line-height: 26px;
}

#social-icons{
  float:right
}

#social-icons li{
  display: inline-block;
  font-size: @fontMd;
}
#social-icons li i{
  font-size: 26px;
  line-height: 26px;
}
p{
  font-size: 13px;
}

h2{
  margin:0
}


img{
  max-width: 100%;
}
h2{
  display: block;
  background-color: #FC5E60;
  color:white;
  text-align: center;
}

#social-icons-big{
  margin:0;
  padding: 0;
  list-style: none;
  font-size: 3em;
}
#social-icons-big li{
  display: inline-block;
  padding: 0 0.2em;
}

.home #social-icons-big li a{
  color: white;
}

#social-icons-big .fa-facebook{
  font-size: 90% !important;
}

#coming{
  p{
    width:350px;
  }
}
.news img{
  max-width: 300px;
}
#resume{
  margin-bottom: 5px;
}

textarea#comments {
  height: 180px;
}

.order-resume .label{
  margin-bottom: 5px;
  display: inline-block;
}

.order-resume ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;
}
.order-resume ul li{
  line-height: 225%;
  font-size: 18px;
}
span.label-order {
  display: inline-block;
  margin-right: 15px;
}

li.total {
  border-top: 2px solid white;
  font-weight: bold;
}

.order-resume span.number {
  display: inline-block;
  text-align: right;
  width: 100px;
}

#message img{
  max-width: 450px;
}

header {
  background-color: black;
  position: fixed;
  width:100%;
  top:0;
  z-index: 1000;
}

a.logo {
  font-size: 22px;
  font-weight: bold;
  color: #CECBB4;
  display: inline-block;
  padding: 9px;
  cursor: pointer;
}

div#social-icons {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
}
iframe.video{
  display: none;
}
.video-bg{
  background-color: black;
}

@media(min-width: @screen-lg-min) {
  .navbar-nav{
    float: none;
    text-align: center;
  }
}


@media(max-width: 1023px)
{
  #iframe.text-right{
    text-align: center;
  }
  .home .col-lg-6{
    text-align: center;
  }
  #coming p{
    margin:auto;
    max-width: 100% !important;
  }

}

@media(max-width: 769px)
{
  body.home {
    background-image: none;
    color:white;
    padding-top: 50px;
  }

  div#coming {
    height: auto;
  }

  #coming{
    background: black url('/assets/front/img/bgplatja.jpg') no-repeat center;
    background-size: cover;
  }

  .form-control.mini{
    width: 24%;
    display: inline-block;
  }
  textarea#comments {
    height: 60px;
  }
}

@media(max-width: @screen-xs-max) {
  #social-icons {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    left: 0;
    z-index: 700;
    margin:0
  }
  body{
    padding-bottom: 40px;
  }
}


